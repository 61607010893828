import { useNotificationStore } from '@stores'
import { post, get } from '@/plugins/httpService'
import router from '@/router'
import preloader from '@/stores/base/preloader/actions'
import { getLocale, getRouteName } from '@/helpers/locale'
import Logger from '@/plugins/errorLogger'
import { resetSesionData, logSesionData } from '@/helpers/session'
import { localizeRouteName } from '@/helpers/route'
import form from '@/composables/form'
import Websocket from '@/plugins/websocket'

export default {
  ...preloader,

  async preload() {
    logSesionData()
    await Websocket.init()
  },

  login() {
    resetSesionData()

    return post('login', {
      Email: 'test@app.test',
      Password: 'password'
    })
      .then((res) => {
        this.authenticated = res.data
        return true
      })
      .catch((err) => err)
  },

  setAuthenticated(data) {
    this.authenticated = data
  },

  async authUser() {
    await get('/auth/user')
      .then((res) => {
        this.authenticated = res.data
      })
  },

  plainLogout() {
    return post('/logout', {})
  },

  logout() {
    resetSesionData()

    return this.plainLogout()
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        window.location.reload()
      })
  },

  send2FACode() {
    Logger.log([':::::send2FACode::::::'])

    get('/2fa-code/send')
      .then((res) => {
        console.log('RES:::', res)
      })
  },

  // Callbacks
  afterLogin(res) {
    this.authenticated = res.data

    let { redirect } = router.currentRoute.value.query

    if (!redirect || typeof redirect === 'undefined') {
      redirect = {
        name: `home.${getLocale()}`
      }
    }

    router.push(redirect)
  },

  afterRegister(res) {
    if (res.message !== 'success') {
      return
    }

    router.push({
      name: getRouteName('register-success')
    })
  },

  after2FA() {
    console.log('after2FA')
    let { redirect } = router.currentRoute.value.query

    if (!redirect) {
      redirect = {
        name: `home.${getLocale()}`
      }
    }

    router.push(redirect)
  },

  afterUserUpdate(response) {
    router.push(localizeRouteName('home'))

    this.authenticated = response.data
  },

  afterOperatorUpdate(response) {
    this.authenticated = response.data
  },

  setUserSettingsForm() {
    const { onInputValue } = form()

    const user = this.authenticated

    onInputValue('user-settings', ['User', 'NameSurname'], user?.NameSurname)
    onInputValue('user-settings', ['User', 'Phone'], user?.Phone)
    onInputValue('user-settings', ['User', 'ReceivedMsgForUser'], user?.ReceivedMsgForUser)
    onInputValue('user-settings', ['User', 'RelatedToUserOrder'], user?.RelatedToUserOrder)
    onInputValue('user-settings', ['User', 'NewInvoices'], user?.NewInvoices)
    onInputValue('user-settings', ['User', 'PaymentChanges'], user?.PaymentChanges)
    onInputValue('user-settings', ['User', 'ReceivedMsgForTeam'], user?.ReceivedMsgForTeam)
    onInputValue('user-settings', ['User', 'RelatedToTeamOrders'], user?.RelatedToTeamOrders)
  },

  afterUserPasswordChange(response) {
    const { onInputValue } = form()

    onInputValue('user-change-password', ['User', 'Password'], '')

    useNotificationStore().push({
      type: 'success',
      title: 'notifications.settings.password_saved',
      translated: false
    })
  },

  setOperatorSettingsForm() {
    const { onInputValue } = form()

    const user = this.authenticated

    onInputValue('operator-personal-settings', ['Operator', 'NameSurname'], user?.NameSurname)
    onInputValue('operator-personal-settings', ['Operator', 'Phone'], user?.Phone)
    onInputValue('operator-personal-settings', ['Operator', 'OperatorsDataChanges'], user?.OperatorsDataChanges)
    onInputValue('operator-personal-settings', ['Operator', 'OperatorsLoginFail'], user?.OperatorsLoginFail)
    onInputValue('operator-personal-settings', ['Operator', 'ClientOrderRegistered'], user?.ClientOrderRegistered)
    onInputValue('operator-personal-settings', ['Operator', 'ClientDataChanges'], user?.ClientDataChanges)
  },

  afterOperatorPasswordChange(response) {
    const { onInputValue } = form()

    onInputValue('operator-change-password', ['Operator', 'Password'], '')
  },

  afterPasswordLinkSend() {
    this.passwordResetMailSend = true
  },

  restPasswordLinkSend() {
    this.passwordResetMailSend = false
  },

  newWorker() {
    router.push({
      name: getRouteName('register-success')
    })
  }
}
