import { useContragentStore } from '@stores'
import { formatPrice, getProductBasePrice, getProductTax } from '@/helpers/price'
import priceComposable from '@/composables/price'

export default {
  getCart: (state) => state.cart,
  getWasFetched: (state) => state.wasFetched,
  getIsFetching: (state) => state.isFetching,
  getIsUpdating: (state) => state.isUpdating,
  getProducts: (state) => state.products.list,
  getIsCartEmpty: (state) => state.products.list <= 0,
  getIsProductsFetching: (state) => state.products.isFetching,
  getCartProductsCount: (state) => {
    if (Number.isNaN(state.products.list?.length)) {
      return 0
    }

    return state.products.list.length * 1
  },
  getSummaryWeight: (state) => {
    const contragentStore = useContragentStore()

    const weight = state.products.list.reduce((accumulator, product) => {
      const packageQuantity = (contragentStore.canSellOnlyPackages ? product.lowest_quantity : 1) || 1

      return accumulator + (product.weight_in_kg * packageQuantity * product.quantity)
    }, 0)

    const roundedWeight = parseFloat(weight).toFixed(2).replace(/\.?0*$/, '')

    return `${roundedWeight.toString().replace('.', ',')} kg`
  },
  getSummaryQuantity: (state) => {
    const sum = state.products.list.reduce((accumulator, product) => {
      return accumulator + product.quantity
    }, 0)

    return `${sum} vnt`
  },
  getSummaryPriceWithoutTax: (state) => {
    const contragentStore = useContragentStore()

    const taxlessPrice = state.products.list.reduce((accumulator, product) => {
      const basePrice = getProductBasePrice(product)

      const packageQuantity = (contragentStore.canSellOnlyPackages ? product.lowest_quantity : 1) || 1

      const factor = product.factor || 1

      return accumulator + basePrice * factor * packageQuantity * product.quantity
    }, 0)

    return formatPrice(taxlessPrice)
  },
  getSummaryTax: (state) => {
    const contragentStore = useContragentStore()

    const taxPrice = state.products.list.reduce((accummulator, product) => {
      const tax = getProductTax(product)

      const packageQuantity = (contragentStore.canSellOnlyPackages ? product.lowest_quantity : 1) || 1

      const factor = product.factor || 1

      return accummulator + tax * factor * packageQuantity * product.quantity
    }, 0)

    return formatPrice(taxPrice)
  },
  getSummaryTotalPrice: (state) => {
    const { getProductPrice } = priceComposable()

    const price = state.products.list.reduce((accumulator, product) => {
      return accumulator + getProductPrice(product, product.quantity, true)
    }, 0)

    return formatPrice(price)
  },
  getSummaryDeposit: (state) => {
    const { getProductDeposit } = priceComposable()

    const deposit = state.products.list.reduce((accumulator, product) => {
      const { factor } = product
      const lowestQty = product.lowest_quantity

      let dep = accumulator + (getProductDeposit(product) * product.quantity)

      if (factor > 0) {
        return dep * factor
      }

      if (lowestQty > 0) {
        return dep * lowestQty
      }
    }, 0)

    return formatPrice(deposit)
  },
  summaryHasMeasuredProduct: (state) => !!state.products.list.find((product) => product.is_measured),
  getCartBuyer: () => useContragentStore().getSelectedItem,
  getCartPayer: () => {
    const contragentStore = useContragentStore()

    const buyer = contragentStore.getSelectedItem

    if (!buyer) {
      return null
    }

    const contragents = contragentStore.getList

    if (!Array.isArray(contragents)) {
      return null
    }

    return contragents.find(({ RECID }) => RECID === buyer.PayerContragentId)
  }
}
