export default {
  getList: (state) => state.list,
  getIsFetching: (state) => state.isFetching,
  getWasFetched: (state) => state.wasFetched,
  getContractList: (state) => state.contractList,
  getIsFetchingContracts: (state) => state.isFetchingContracts,
  getWasFetchedContracts: (state) => state.wasFetchedContracts,
  getFavoriteList: (state) => state.favoriteList,
  getIsFetchingFavorites: (state) => state.isFetchingFavorites,
  getLayout: (state) => (state.layout === 'list' ? 'list' : 'grid'),
  getPage: (state) => state.page,
  getPages: (state) => state.pages,
  getPerPage: (state) => {
    const options = [25, 50, 75, 100]

    return options.find((option) => option === state.perPage * 1) || 25
  },
  getOrderBy: (state) => {
    const options = ['price_asc', 'price_desc', 'title_asc', 'title_desc']

    return options.find((option) => option === state.orderBy) || 'price_asc'
  },
  getInnerPageData: (state) => state.innerPage.data,
  isInnerPageFetching: (state) => state.innerPage.isFetching,
  getFilters: (state) => state.filters,
  getSearchTerm: (state) => state.searchTerm,
  getProductsFetchPayload: (state) => ({
    ...state.filters,
    searchTerm: state.searchTerm || undefined,
    page: state.page,
    perPage: state.perPage * 1,
    orderBy: state.orderBy
  })
}


