import { useDisplayGroupStore } from '@stores'
import { post, get } from '@/plugins/httpService'
import preloader from '@/stores/base/preloader/actions'
import { setUrlParameter } from '@/helpers/url'
import localStorageEnum from '@/enums/localStorageEnum'
import actionTimeoutEnum from '@/enums/actionTimeoutEnum'
import {localizeRouteName, unlocalizeRouteName} from '@/helpers/route.js'
import router from '@/router'

const RESOURCE = 'products'

let timeout = null

export default {
  ...preloader,

  preload() {
    if (this.getSearchTerm) {
      this.fetchCount()
    }
  },

  async fetchList() {
    const payload = this.getProductsFetchPayload

    const isCartView = router.currentRoute.value.name === localizeRouteName('cart')

    this.isFetching = true
    post(`/${RESOURCE}`, isCartView ? ({ ...payload, searchTerm: undefined }) : payload)
      .then(({ data, pages, perPage }) => {
        this.list = data
        this.pages = pages
        this.perPage = perPage
      })
      .finally(() => {
        this.isFetching = false
        this.wasFetched = true
      })
  },

  async fetchCount() {
    const payload = this.getProductsFetchPayload

    const { data } = await post(`/${RESOURCE}/count`, payload)

    useDisplayGroupStore().setCategoriesProductsCount(data)
  },

  async openFirstCategory() {
    const displayStore = useDisplayGroupStore()

    const notEmptyCategoriesKeys = Object.keys(displayStore.getCategoriesProductsCount)
      .filter((categoryId) => categoryId)
      .map((categoryId) => categoryId * 1)

    const findFirstCategory = (menu) => {
      for (let index = 0; index < menu.length; index++) {
        const menuItem = menu[index]

        if (notEmptyCategoriesKeys.includes(menuItem.id)) {
          return menuItem.id
        }

        const subMenu = menuItem.subMenu || []

        if (Array.isArray(subMenu) && subMenu.length) {
          const foundCategory = findFirstCategory(subMenu)

          if (foundCategory !== null) {
            return foundCategory
          }
        }
      }

      return null
    }

    const foundCategory = findFirstCategory(displayStore.getListTree)

    if (
      foundCategory
      && (
        !['home', 'category'].includes(unlocalizeRouteName(router.currentRoute.value.name))
        || !(
          unlocalizeRouteName(router.currentRoute.value.name) === 'category'
          && Object.keys(router.currentRoute.value.params).includes('category1')
          && notEmptyCategoriesKeys.includes(router.currentRoute.value.params.category1 * 1)
        )
      )
    ) {
      await router.push({ name: localizeRouteName('category'), params: { category1: foundCategory }, query: { search: router.currentRoute.value.query?.search || undefined } })

      return true
    }

    return false
  },

  fetchInnerPage(id) {
    this.innerPage.isFetching = true

    const product = this.getList.find((item) => item.id === id)
    if (product) {
      this.setInnerPageData(product)
      this.innerPage.isFetching = false
      return
    }

    get(`/${RESOURCE}/${id}`)
      .then(({ data }) => {
        this.setInnerPageData(data)
      })
      .catch(() => {
        this.setInnerPageData(null)
        router.push({ name: localizeRouteName('home') })
      })
      .finally(() => {
        this.innerPage.isFetching = false
      })
  },

  setLayout(layout) {
    this.layout = layout

    localStorage.setItem(localStorageEnum.products.layout_key, this.layout)
  },

  setPage(page, fetch = true, changeUrl = true) {
    this.page = page

    if (changeUrl) {
      setUrlParameter('page', this.page)
    }

    if (fetch) {
      this.fetchList()
    }
  },

  setPages(pages) {
    this.pages = pages
  },

  setPerPage(perPage) {
    if (![25, 50, 75, 100].includes(perPage)) {
      return
    }

    this.perPage = perPage

    setUrlParameter('per-page', this.perPage)

    this.fetchList()
  },

  setOrderBy(orderBy) {
    this.orderBy = orderBy

    setUrlParameter('order-by', this.orderBy)

    this.fetchList()
  },

  setInnerPageData(innerPage) {
    this.innerPage.data = innerPage
  },

  async setSearchTerm(term, immediate = false) {
    this.searchTerm = term.trim()

    useDisplayGroupStore().unsetCategoriesProductsCount()

    clearTimeout(timeout)

    timeout = setTimeout(async () => {
      this.setPage(1, false, true)

      await this.fetchCount()

      const redirected = !!(this.wasJustSearched ? await this.openFirstCategory() : false)

      this.setJustSearched(false)

      if (!redirected) {
        this.fetchList()
      }

      setUrlParameter('search', this.searchTerm)
    }, !immediate ? actionTimeoutEnum.search : 0)
  },

  toggleFavoriteProduct(product) {
    if (product.id && product.id === this.getInnerPageData?.id) {
      this.setInnerPageData(product)
    }

    const favoritesIndex = this.getFavoriteList.findIndex((item) => item.id === product.id)

    if (this.getFavoriteList[favoritesIndex]) {
      this.favoriteList.splice(favoritesIndex, 1)
    }

    const productsIndex = this.getList.findIndex((item) => item.id === product.id)

    if (this.getList[productsIndex]) {
      this.list[productsIndex] = product
    }

    const contractsIndex = this.getContractList.findIndex((item) => item.id === product.id)

    if (this.getContractList[contractsIndex]) {
      this.contractList[contractsIndex] = product
    }

    return product
  },

  unsetFilters() {
    this.filters = {
      specialOffers: false,
      categoryId: null
    }
  },

  setFilter(key, value) {
    this.filters[key] = value
  },

  fetchContracts() {
    this.isFetchingContracts = true

    post(`/${RESOURCE}/contracts`)
      .then(({ data }) => {
        this.contractList = data
      }).finally(() => {
        this.isFetchingContracts = false
        this.wasFetchedContracts = true
      })
  },

  fetchFavorites() {
    this.isFetchingFavorites = true

    post(`/${RESOURCE}/favorites`)
      .then(({ data }) => {
        this.favoriteList = data
      })
      .finally(() => {
        this.isFetchingFavorites = false
      })
  },

  getProductNameById(id) {
    return get(`/${RESOURCE}/${id}/name`)
      .then((res) => res.data)
  },

  setJustSearched(status) {
    this.wasJustSearched = !!status
  }
}
